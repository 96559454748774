<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-show="isSubAgent" v-model="query.subAgentId"
					@change="getfetchDefaultCompanies" filterable clearable placeholder="子代理" class="handle-select mr10"
					style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-model="query.companyId" filterable clearable
					placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" v-if="canAddCompany" type="primary" :class="[theme]" class="handle-add mr100"
					@click="showForm">新增进度标签</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
				:row-class-name="tableRowClassName" row-key="id" :class="{ 'not-sort': inputs }">
				<el-table-column prop="index" align="center" width="50"></el-table-column>

				<el-table-column prop="str" label="跟进字段名称" align="center"></el-table-column>

				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" :disabled="canEditCompany" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row)">编辑</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.$index, scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-dialog title="编辑标签" v-model="editVisible" width="500px" @close="closeeditVisibleDialog">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="当前行" prop="index"><el-input size="small" disabled
						v-model="form.index"></el-input></el-form-item>
				<el-form-item label="标签名称" prop="str"><el-input size="small"
						v-model="form.str"></el-input></el-form-item>
				<!-- <el-form-item label="排序" prop="nextIndex"><el-input size="small" v-model="form.nextIndex"></el-input></el-form-item> -->
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeeditVisibleDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="updateCustomerSchedule('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<el-dialog title="添加标签" v-model="addVisible" width="500px" @close="closeaddVisibleDialog"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="标签名称" prop="str"><el-input size="small"
						v-model="form.str"></el-input></el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeaddVisibleDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="addCustomerSchedule('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		list,
		delCompany,
		fetchCompanyById,
		fetchCompanyByAgentId,
		uptoken,
		uptoken1
	} from '../api/companyIndex.js';
	import {
		fetchAgent
	} from '../api/agentIndex';
	import {
		fetchDefaultCompanies
	} from '../api/companyIndex';
	import {
		getCustomerSchedule,
		updateCustomerSchedule,
		addCustomerSchedule,
		deleteCustomerSchedule
	} from '../api/tag.js';
	import Sortable from 'sortablejs';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	export default {
		name: 'company',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					userId: localStorage.getItem('user'),
					subAgentId: ''
				},
				tableData: [],
				companys: [],
				agents: [],
				addVisible: false,
				editVisible: false,
				canAddCompany: true,
				isAdmin: false,
				pageTotal: 0,
				isSubAgent: false,
				loading1: false,
				form: {
					str: ''
				},
				loading: false,
				inputVisible: false,
				inputValue: ''
			};
		},

		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (role === 'normal') {
				this.canAddCompany = false;
			}
			if (role === 'agent') {
				this.isNotAdmin = false;
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}
			if (role === 'admin') {
				this.isAdmin = true;
			}
			this.init();
		},
		mounted() {
			// 调用 table拖拽排序
		},

		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
					if (res.data.length > 0) {
						this.query.companyId = this.companys[0].value;
						this.query.pageIndex = 1;
						this.getData();
					}
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			rowDrop() {

				let tbody = document.querySelector('.el-table__body-wrapper tbody');
				console.log(tbody, '手上蓝思科技李经理')
				let _this = this;
				_this.so = Sortable.create(tbody, {
					group: {
						name: 'str',
						pull: true,
						put: true
					},
					animation: 150, // 动画参数
					sort: true,
					filter: '.not-sort', // 过滤.not-sort的元素
					onEnd(evt) {
						let data = {
							companyId: _this.query.companyId,
							str: evt.item.cells[1].innerText,
							index: evt.oldIndex + 1,
							nextIndex: evt.newIndex + 1
						};
						updateCustomerSchedule(data).then(res => {
							if (res.code == 200) {
								_this.so.destroy();
								setTimeout(function() {
									_this.getData();
									_this.$message.success('修改成功');
								}, 2000);
							} else {
								_this.$message.error(res.message);
							}
						});
					}
				});
			},
			handleClose(tag) {
				this.tableData.splice(this.tableData.indexOf(tag), 1);
			},

			showInput() {
				this.inputVisible = true;
				this.$nextTick(_ => {
					console.log('showInput');
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					this.tableData.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			},

			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
			},
			agentChange(agentId) {
				this.query.companyId = '';
				this.query.userId = '';
				this.form.companyId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},

			getData() {
				this.tableData = [];
				getCustomerSchedule(this.query.companyId).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
						this.rowDrop();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showForm() {
				if (this.query.companyId) {
					this.addVisible = true;
				} else {
					this.$message.error('请选择企业');
				}
			},

			// 点击确定上传信息
			addCustomerSchedule(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let data = {
							companyId: this.query.companyId,
							str: this.form.str
						};
						addCustomerSchedule(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.data);
								this.addVisible = false;
								this.getData();
							} else {
								this.$message.error(res.message);
								this.getData();
							}
						});
					}
				});
			},

			// 编辑操作
			handleEdit(index, row) {
				this.editVisible = true;
				this.form = row;
			},
			// 点击修改信息
			updateCustomerSchedule(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let data = {
							companyId: this.query.companyId,
							str: this.form.str,
							index: this.form.index,
							nextIndex: this.form.nextIndex
						};
						updateCustomerSchedule(data).then(res => {
							if (res.code == 200) {
								this.$message.success('修改成功');
								this.editVisible = false;
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 删除操作
			handleDelete(index, row) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							index: row.index,
							companyId: this.query.companyId
						};
						deleteCustomerSchedule(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.pageTotal = this.pageTotal - 1;
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},

			// 点击取消
			closeeditVisibleDialog(formName) {
				this.form = {
					str: '',
					index: '',
					nextIndex: ''
				};
				this.editVisible = false;
				this.getData();
			},
			closeaddVisibleDialog() {
				this.form = {
					str: '',
					index: '',
					nextIndex: ''
				};
				this.addVisible = false;

				this.getData();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.el-form-item__label {
		font-size: 13px !important;
	}

	.hide .el-upload--picture-card {
		display: none;
	}

	.content_top {
		display: flex;
		justify-content: space-around;
	}

	.content_bottom {
		display: flex;
		justify-content: space-around;
	}

	.content_middle {
		display: flex;
		margin: 0.625rem 0 0.625rem 0;
		/* margin-bottom: 0.625rem; */
		justify-content: space-around;
	}

	.ywlx:hover {
		color: #00a854;
		background: none;
	}

	.content_top {
		display: flex;
		margin: auto;
	}

	::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
		cursor: move !important;
	}
</style>